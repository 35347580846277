import {Status, V1PeriodUnit} from '@wix/ambassador-membership-api/types'
import {getFormattedMoney, isRsvp, PAID_PLANS_APP_DEF_ID} from '@wix/wix-events-commons-statics'
import {PlanInfo, PlanList} from '@wix/wix-events-commons-statics/dist/types/exported-types'
import {TranslationFunction} from 'i18next'
import {IWixAPI} from '@wix/native-components-infra/dist/es/src/types/types'
import {State} from '../types'
import {isMembershipOffersVisible} from './settings'

export const getBestPurchasedPlan = (state: State): PlanInfo => state.paidPlans.purchasedPlanList?.[0]

export const getPurchasedPlans = (state: State): PlanInfo[] => state.paidPlans.purchasedPlanList

export const getSelectedPaidPlan = ({paidPlans: {purchasedPlanList, selectedPaidPlanOrderId}}: State) =>
  purchasedPlanList.find(plan => plan.planOrderId === selectedPaidPlanOrderId)

export const showPaidPlans = (state: State) =>
  isMembershipOffersVisible(state.component.settings) && !isRsvp(state.event)

export const showPaidPlansPicker = (state: State) => getPlans(state).length && !isRsvp(state.event)

export const planHasRemainingCredits = (plan: PlanInfo) => !!plan?.remainingCredits

export const allPlansPurchased = (state: State) => getNotPurchasedPlans(state).length === 0

export const getNotPurchasedPlans = (state: State) => getPlans(state).filter(plan => !plan.purchased)

export const getBestNonExpiredPlan = (plans: PlanList) => plans.find(planHasRemainingCredits)

export const getPaidPlansSectionUrl = async (wixCodeApi: IWixAPI) => {
  const siteStructure = await wixCodeApi.site.getSiteStructure()
  return siteStructure.pages.find((page: any) => page.applicationId === PAID_PLANS_APP_DEF_ID).url || ''
}

export const getPlans = (state: State): PlanInfo[] => state.paidPlans.planList

export const getPurchasablePlans = (state: State) =>
  getPlans(state).filter(plan => !plan.purchased || (plan.purchased && !plan.remainingCredits))

export const getNotPurchasedPlanIds = (state: State) => getPurchasablePlans(state).map(plan => plan.id)

export const getPaidPlanPromoDiscount = (state: State) => Number(getBestPlan(state)?.discount?.percentOffRate)

export const getBestPlan = (state: State): PlanInfo => state.paidPlans.planList?.[0]

export const getPurchasedPlanByOrderId = (state: State, planOrderId: string) =>
  getPurchasedPlans(state).find(plan => plan.planOrderId === planOrderId)

export const getPlansWithValidityText = (state: State, t: TranslationFunction): PlansWithValidityText =>
  getPlans(state).map(plan => {
    const {
      paymentOptions: {validFor, freeTrialDays, price},
    } = plan

    const forever = validFor?.forever ?? false
    const free = !Number(price?.amount ?? 0)

    const infoItems: string[] = []

    if (forever && free) {
      infoItems.push(t('membership_free_plan'))
    } else if (!forever && validFor?.period) {
      const validityCycleMap = {
        [V1PeriodUnit.DAY]: {
          singular: 'membership_valid_for_one_day',
          plural: 'membership_valid_for_days',
        },
        [V1PeriodUnit.MONTH]: {
          singular: 'membership_valid_for_one_month',
          plural: 'membership_valid_for_months',
        },
        [V1PeriodUnit.WEEK]: {
          singular: 'membership_valid_for_one_week',
          plural: 'membership_valid_for_weeks',
        },
        [V1PeriodUnit.YEAR]: {
          singular: 'membership_valid_for_one_year',
          plural: 'membership_valid_for_years',
        },
      }

      const translations = validityCycleMap[validFor.period.unit ?? V1PeriodUnit.MONTH]
      const translation = validFor.period.amount === 1 ? translations.singular : translations.plural

      infoItems.push(t(translation, {amount: validFor.period.amount}))
    }

    if (freeTrialDays) {
      if (forever) {
        infoItems.push(t('membership_free_trial_days_unlimited', {count: freeTrialDays}))
      } else {
        infoItems.push(t('membership_free_trial_days', {count: freeTrialDays}))
      }
    }

    return {
      plan,
      infoLine: infoItems.join(' + '),
    }
  })

export const hasPurchasedPlans = (state: State) => Boolean(getPurchasedPlans(state).length)

export const getPlanBenefitText = (planInfo: PlanInfo, t: TranslationFunction, useRemainingCredits?: boolean) => {
  const percent = Number(planInfo?.discount?.percentOffRate ?? '0')
  const amount = (useRemainingCredits ? planInfo?.remainingCredits : planInfo?.credits) ?? 0
  let translationKey = 'membership_discount'

  if (amount === 0) {
    translationKey = 'membership_discount_finished'
  } else if (amount === 1) {
    translationKey = 'membership_discount_singular'
  }

  return t(translationKey, {percent, amount})
}

export const getPlanPriceText = ({paymentOptions}: PlanInfo, t: TranslationFunction) => {
  let priceText = getFormattedMoney(paymentOptions.price)

  if (paymentOptions.recurring) {
    const unit = paymentOptions.validFor.period.unit ?? V1PeriodUnit.MONTH

    const translationMap = {
      [V1PeriodUnit.YEAR]: 'membership_month_year',
      [V1PeriodUnit.MONTH]: 'membership_month_month',
      [V1PeriodUnit.WEEK]: 'membership_month_week',
    }

    priceText += ` / ${t(translationMap[unit])}`
  }

  return priceText
}

export const planVisible = (plan: PlanInfo) => plan.purchased || (plan.visible && plan.status === Status.ACTIVE)

export const getSortedPlanList = (planList: PlanList): PlanList =>
  [...planList].sort((a, b) => {
    const aDiscount = Number(a.discount?.percentOffRate ?? 0)
    const bDiscount = Number(b.discount?.percentOffRate ?? 0)

    return bDiscount - aDiscount
  })

export interface PlanWithValidityText {
  plan: PlanInfo
  infoLine: string
}

export type PlansWithValidityText = PlanWithValidityText[]
