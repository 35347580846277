import {getEventId} from '@wix/wix-events-commons-statics'
import {openDialogModal} from '../../commons/services/modal'
import {GetState} from '../types/state'
import {createActions, callAPI} from '../../commons/actions/data-action-helper'

export const GET_POLICIES = createActions('GET_POLICIES')
export const TOGGLE_AGREE_POLICIES = 'TOGGLE_AGREE_POLICIES'
export const RESET_POLICIES = 'RESET_POLICIES'

export const resetPolicies = () => ({
  type: RESET_POLICIES,
})

export const toggleAgreePolicies = () => ({
  type: TOGGLE_AGREE_POLICIES,
})

export const getPolicies = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  dispatch(callAPI(GET_POLICIES, getEventId(state.event)))
}

export const openPolicyModal = (policyId: string) => (dispatch: Function, getState: GetState) => {
  const state = getState()
  dispatch(
    openDialogModal({
      type: 'policy',
      params: {
        policyId,
        eventId: getEventId(state.event),
      },
    }),
  )
}
