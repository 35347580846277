import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {ComponentSettings, GetState} from '../types'

export const UPDATE_STYLE_PARAMS = 'UPDATE_STYLE_PARAMS'
export const UPDATE_COMPONENT_SETTINGS_DRAFT = createActions('UPDATE_COMPONENT_SETTINGS_DRAFT')
export const PUBLISH_COMPONENT_SETTINGS = createActions('PUBLISH_COMPONENT_SETTINGS')

export const updateStyleParams = (payload: object) => ({type: UPDATE_STYLE_PARAMS, payload})

export const updateComponentSettings = (settings: ComponentSettings) =>
  callAPI(UPDATE_COMPONENT_SETTINGS_DRAFT, settings)

export const publishComponentSettings = () => (dispatch: Function, getState: GetState) =>
  dispatch(callAPI(PUBLISH_COMPONENT_SETTINGS, getState().component.settings))
