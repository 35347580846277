export const ROOT_COMPONENT_ID = 'events-details-page-root'

export const MAX_TICKETS_PER_RESERVATION = 20

export enum UserRole {
  ADMIN = 'Admin',
  MEMBER = 'Member',
  Visitor = 'Visitor',
}

export enum FormStep {
  BuyerDetails = 'form',
  TicketsDetails = 'ticketsDetails',
  Policies = 'policies',
  Payment = 'payment',
}

export const CHECKOUT_STEPS = [FormStep.BuyerDetails, FormStep.TicketsDetails, FormStep.Policies, FormStep.Payment]

export const RSVP_STEPS = [FormStep.BuyerDetails, FormStep.Policies]
