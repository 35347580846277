import {LocalStorage} from '@wix/wix-events-commons-statics'
import {DETAILS_ROUTE} from '../constants/navigation'

const CURRENT_PATH_LOCAL_STORAGE_KEY = 'currentPagePath'
const CURRENT_ROUTE_LOCAL_STORAGE_KEY = 'currentPageRoute'

export const isDetailsPageOpened = async () => {
  const currentPage = await getCurrentPath()
  return !(currentPage.includes(DETAILS_ROUTE.FORM) || currentPage.includes(DETAILS_ROUTE.THANK_YOU_MESSAGES))
}

export const getCurrentRoute = () => LocalStorage.getItem(CURRENT_ROUTE_LOCAL_STORAGE_KEY)

export const setCurrentRoute = (route: string) => LocalStorage.setItem(CURRENT_ROUTE_LOCAL_STORAGE_KEY, route)

export const monitorCurrentRoute = async cb => LocalStorage.monitorItem(CURRENT_ROUTE_LOCAL_STORAGE_KEY, cb)

export const getCurrentPath = async () => (await LocalStorage.getItem(CURRENT_PATH_LOCAL_STORAGE_KEY)) || ''

export const setCurrentPath = (path: string) => LocalStorage.setItem(CURRENT_PATH_LOCAL_STORAGE_KEY, path)
