import {isCalendarLayout} from '../../commons/selectors/settings'
import {TextSection} from '../../commons/types/widget-settings'
import {getComponentConfig} from '../selectors/settings'
import {GetState} from '../types/state'
import {calendarTextsSectionChanged} from './calendar-layout'

export const textsSectionChanged = (id: TextSection) => (dispatch: Function, getState: GetState) => {
  if (isCalendarLayout(getComponentConfig(getState()))) {
    dispatch(calendarTextsSectionChanged(id))
  }
}
