import {getEventId, EVENTS_APP_ID, EVENTS_SECTION_ID, getSlug} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {GetState, StoreExtraArgs} from '../types'
import {
  getPurchasedPlanByOrderId,
  planHasRemainingCredits,
  getNotPurchasedPlanIds,
  getPaidPlansSectionUrl,
} from '../selectors/paid-plans'
import {isMobile} from '../../commons/selectors/environment'
import {getDiscount} from './checkout'

export const GET_PLAN_LIST = createActions('GET_PLAN_LIST')
export const GET_PURCHASED_PLAN_LIST = createActions('GET_PURCHASED_PLAN_LIST')
export const SET_SELECTED_PLAN_ORDER_ID = 'SET_SELECTED_PLAN_ORDER_ID'

export const getPlanList = () => async (dispatch: Function, getState: GetState) =>
  dispatch(callAPI(GET_PLAN_LIST, getEventId(getState().event)))

export const getPurchasedPlanList = () => (dispatch: Function, getState: GetState) =>
  dispatch(callAPI(GET_PURCHASED_PLAN_LIST, getEventId(getState().event)))

export const applyPlan = (eventId: string, reservationId: string, planOrderId: string) => (
  dispatch: Function,
  getState: GetState,
) => {
  const targetPlan = getPurchasedPlanByOrderId(getState(), planOrderId)
  const hasRemainingCredits = planHasRemainingCredits(targetPlan)
  dispatch(setSelectedPlanOrderId(targetPlan && hasRemainingCredits ? planOrderId : undefined))
  dispatch(getDiscount(eventId, reservationId))
}

export const openMembershipPicker = ({titleText, contentText, buttonText}: OpenMembershipPickerArgs) => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const state = getState()
  const slug = getSlug(state.event)
  const returnUrl = isMobile(state) ? `${slug}/${DETAILS_ROUTE.PICK_TICKETS}` : slug

  const queryParams = {
    originAppDefinitionId: EVENTS_APP_ID,
    planIds: getNotPurchasedPlanIds(state).join(','),
    navigateToSectionProps: btoa(
      JSON.stringify({
        appDefinitionId: EVENTS_APP_ID,
        sectionId: EVENTS_SECTION_ID,
        state: returnUrl,
      }),
    ),
    verticalStatusContent: btoa(
      JSON.stringify({
        titleText,
        contentText,
        buttonText,
      }),
    ),
  }
  const path = `?appSectionParams=${encodeURIComponent(JSON.stringify(queryParams))}`
  const sectionUrl = await getPaidPlansSectionUrl(wixCodeApi)

  wixCodeApi.location.to(`${sectionUrl}${path}`)
}

export const setSelectedPlanOrderId = (planOrderId: string) => ({
  type: SET_SELECTED_PLAN_ORDER_ID,
  payload: {planOrderId},
})

interface OpenMembershipPickerArgs {
  titleText: string
  contentText: string
  buttonText: string
}
