import jwtDecode from 'jwt-decode'
import {InstanceValues} from '../types/instance'
import {InstanceState} from '../types/state'

export const decodeInstance = (instance = '') => {
  try {
    const decodedInstance = jwtDecode<InstanceValues>(instance)
    return {
      ...decodedInstance,
      visitorId: decodedInstance.aid || decodedInstance.uid || null,
      msid: decodedInstance.metaSiteId || null,
      instanceId: decodedInstance.metaSiteId || null,
      siteIsTemplate: Boolean(decodedInstance.siteIsTemplate) || false,
      demoMode: Boolean(decodedInstance.demoMode) || false,
      siteOwnerId: decodedInstance.siteOwnerId || null,
    }
  } catch (e) {
    return {
      visitorId: null,
      msid: null,
      instanceId: null,
      siteIsTemplate: false,
      demoMode: false,
      siteOwnerId: null,
    }
  }
}

export const isDemoMode = (instance: InstanceState) => instance.demoMode || instance.siteIsTemplate
