import {without} from 'lodash'
import {AnyAction} from 'redux'
import {CLOSE_ALL_EVENTS, TOGGLE_EVENT_DETAILS} from '../actions/events'
import {MemberPageState} from '../types/state'

const defaultState: MemberPageState['expandedEvents'] = []

export const expandedEvents = (state = defaultState, action: AnyAction): MemberPageState['expandedEvents'] => {
  switch (action.type) {
    case TOGGLE_EVENT_DETAILS:
      const eventId = action.payload
      if (state.includes(eventId)) {
        return without(state, eventId)
      } else {
        return [...state, eventId]
      }
    case CLOSE_ALL_EVENTS:
      return defaultState
    default:
      return state
  }
}
