import * as moment from 'moment-timezone'
import {AnyAction} from 'redux'
import {
  ADD_CALENDAR_MONTH,
  CLOSE_MONTHLY_CALENDAR_EVENT,
  CLOSE_MONTHLY_CALENDAR_POPUP,
  OPEN_MONTHLY_CALENDAR_EVENT,
  OPEN_MONTHLY_CALENDAR_POPUP,
  SET_CALENDAR_DATE,
  SET_CALENDAR_TODAY,
  SUB_CALENDAR_MONTH,
} from '../actions/calendar-layout'
import {CalendarLayout} from '../types/state'

const defaultState: CalendarLayout = {
  referenceDate: null,
  borderWidth: 1,
  monthly: {
    selectedDate: null,
    selectedEventId: null,
  },
}

export const calendarLayout = (state = defaultState, action: AnyAction): CalendarLayout => {
  switch (action.type) {
    case ADD_CALENDAR_MONTH:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: moment(action.payload)
          .clone()
          .startOf('month')
          .add(1, 'month')
          .valueOf(),
      }
    case SUB_CALENDAR_MONTH:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: moment(action.payload)
          .clone()
          .startOf('month')
          .subtract(1, 'month')
          .valueOf(),
      }
    case SET_CALENDAR_TODAY:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: moment()
          .startOf('day')
          .valueOf(),
      }
    case SET_CALENDAR_DATE:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        referenceDate: moment(action.payload)
          .clone()
          .startOf('day')
          .valueOf(),
      }
    case OPEN_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: null,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
        },
      }
    case OPEN_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: action.payload.eventId,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedEventId: null,
        },
      }
    default:
      return state
  }
}
