import {
  getEventId,
  getEventTitle,
  getFormattedFullDate,
  getFormattedLocation,
  getTaxConfig,
} from '@wix/wix-events-commons-statics'
import {getFullLocale, isEditor, isMobile} from '../../commons/selectors/environment'
import {openDialogModal, openModal} from '../../commons/services/modal'
import {getInvoice, getOrderedTickets} from '../selectors/placed-order'
import {getReservationId} from '../selectors/reservation'
import {getSortedSelectedTickets} from '../selectors/selected-tickets'
import {GetState, StoreExtraArgs} from '../types'
import {OrderSummaryModalData} from '../../modal/order-summary/interfaces'
import {navigateToDetails, navigateToTicketsPicker} from './navigation'
import {cancelReservation} from './reservation'

export const openCheckoutUnavailable = () => (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) =>
  dispatch(
    openDialogModal({
      type: 'checkout-unavailable',
      onClose: () => wixCodeApi.location.to(wixCodeApi.location.url),
    }),
  )

export const openTicketsDetailsModal = () => (
  dispatch: Function,
  getState: GetState,
  {platformAPIs}: StoreExtraArgs,
) => {
  const state = getState()
  const {event, tickets} = state
  const modalType = 'order-summary'

  const data: OrderSummaryModalData = {
    eventTitle: getEventTitle(event),
    eventDate: getFormattedFullDate(event, getFullLocale(state)),
    eventLocation: getFormattedLocation(event),
    tickets: getOrderedTickets(getSortedSelectedTickets(state), tickets),
    invoice: getInvoice(state),
    taxConfig: getTaxConfig(event),
  }

  platformAPIs.pubSub.publish(modalType, data, true)

  return dispatch(
    openModal({
      type: modalType,
    }),
  )
}

export const openTimeExpiredModal = () => (dispatch: Function, getState: GetState) => {
  if (isEditor(getState())) {
    return null
  }
  return dispatch(
    openDialogModal({
      type: 'time-expired',
      onClose: () => dispatch(onCloseTimeExpiredModal()),
    }),
  )
}

const onCloseTimeExpiredModal = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const eventId = getEventId(state.event)
  const reservationId = getReservationId(state)

  dispatch(cancelReservation(eventId, reservationId))
  if (isMobile(state)) {
    dispatch(navigateToTicketsPicker())
  } else {
    dispatch(navigateToDetails())
  }
}

export const openCantCompletePaymentModal = () =>
  openDialogModal({
    type: 'cant-complete-payment',
  })
