import {IWidgetControllerConfig, IWixAPI, IWixWindow} from '@wix/native-components-infra/dist/src/types/types'
import {getLinguisticHeaderFromUrl} from '@wix/wix-events-commons-statics'
import {Multilingual} from '../types/state'

export const getLinguisticHeader = (controller: IWidgetControllerConfig) => {
  const {
    wixCodeApi: {
      window: {multilingual},
    },
    appParams: {
      instanceId,
      baseUrls: {iframeUrl},
    },
  } = controller

  if (multilingual.isEnabled && !iframeUrl) {
    const language = multilingual.siteLanguages.find(lang => {
      return lang.languageCode === multilingual.currentLanguage
    })
    if (language) {
      return `${language.languageCode}|${language.locale}|${language.isPrimaryLanguage}|${instanceId}`
    }
  }
  if (iframeUrl) {
    return getLinguisticHeaderFromUrl(iframeUrl)
  }
}

export const getMultilingualParams = (controller: IWidgetControllerConfig) => {
  const {
    wixCodeApi: {
      window: {multilingual},
    },
  } = controller

  if (multilingual.isEnabled) {
    const language = multilingual.siteLanguages.find(lang => {
      return lang.languageCode === multilingual.currentLanguage
    })
    if (language) {
      return `&lang=${language.languageCode}&dateNumberFormat=${language.locale}&isPrimaryLanguage=${language.isPrimaryLanguage}`
    }
  }
}

const isPrimaryLanguage = ({multilingual}: IWixWindow): boolean => {
  if (multilingual.isEnabled) {
    const siteLanguage = multilingual.siteLanguages.find(lang => lang.languageCode === multilingual.currentLanguage)
    return siteLanguage?.isPrimaryLanguage ?? false
  }
  return true
}

export const getMultilingualInitialState = (wixCodeApi: IWixAPI): Multilingual => {
  const {currentLanguage, isEnabled} = wixCodeApi.window.multilingual
  return {
    currentLanguage,
    isEnabled,
    isPrimaryLanguage: isPrimaryLanguage(wixCodeApi.window),
  }
}
