export enum TextSection {
  RSVP_CLOSED = 'rsvp-closed',
  RSVP = 'rsvp',
  TODAY = 'today',
  NONE = '',
}

export enum DesignSection {
  BUTTONS = 'buttons',
  RIBBONS = 'ribbons',
  EVENT_DETAILS_POPUP = 'eventDetailsPopup',
  EVENT_LIST_POPUP = 'eventListPopup',
  NONE = '',
}
