import {isTicketed} from '@wix/wix-events-commons-statics'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {mergeCompSettings} from '../reducers/component'
import {GetState} from '../types'
import {updateComponentSettings} from './component'
import {navigateToDetails, navigateToForm, navigateToTicketForm} from './navigation'

enum SettingsActions {
  UPDATE_COMP_SETTINGS = 'UPDATE_COMP_SETTINGS',
  SITE_SETTINGS = 'SITE_SETTINGS',
  NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE',
}

export const updateSettings = action => async (dispatch: Function, getState: GetState) => {
  const state = getState()
  switch (action.type) {
    case SettingsActions.UPDATE_COMP_SETTINGS: {
      const newSettings = mergeCompSettings(action.payload, state.component.settings)
      dispatch(updateComponentSettings(newSettings))
      break
    }
    case SettingsActions.SITE_SETTINGS: {
      dispatch(updateSiteSettings(action.settings))
      break
    }
    case SettingsActions.NAVIGATE_TO_PAGE: {
      if (action.subPage === 'DETAILS') {
        await dispatch(navigateToDetails())
      }
      if (action.subPage === 'REGISTRATION') {
        if (isTicketed(state.event)) {
          await dispatch(navigateToTicketForm())
        } else {
          await dispatch(navigateToForm())
        }
      }
      break
    }
    default: {
      return
    }
  }
}
