import {State} from '../types'

export const getInvoice = (state: State) => state.invoice.invoice

export const getCouponCode = (state: State) => state.invoice?.coupon?.code

export const getCouponTicketId = (state: State) => state.invoice.coupon.ticketId

export const couponSubmitted = (state: State) => state.invoice.coupon.submitted

export const getInvoiceError = (state: State) => state.invoice.error
