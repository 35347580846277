import {AnyAction} from 'redux'
import {TAB} from '../../commons/constants/navigation'
import {CLOSE_LIST_LAYOUT_ITEMS, HIDE_RIBBONS, OPEN_LIST_LAYOUT_ITEM, SHOW_RIBBONS} from '../actions/layout'
import {SettingsTab, SETTINGS_TAB_CHANGED} from '../actions/settings-panel'
import {Layout} from '../types/state'

const defaultState: Layout = {
  listItemOpened: '',
  forceShowRibbons: false,
  settingsDisplayTabOpened: false,
}

export const layout = (state = defaultState, action: AnyAction): Layout => {
  switch (action.type) {
    case OPEN_LIST_LAYOUT_ITEM:
      return {
        ...state,
        listItemOpened: action.payload,
      }
    case CLOSE_LIST_LAYOUT_ITEMS:
      return {
        ...state,
        listItemOpened: '',
      }
    case SHOW_RIBBONS:
      return {
        ...state,
        forceShowRibbons: true,
      }
    case HIDE_RIBBONS:
      return {
        ...state,
        forceShowRibbons: false,
      }
    case SETTINGS_TAB_CHANGED:
      return {
        ...state,
        settingsDisplayTabOpened: [SettingsTab.LIST_DISPLAY, TAB.DISPLAY].includes(action.payload),
      }
    default:
      return state
  }
}
