import {Dispatch} from 'redux'
import {CommonStoreExtraArgs, GetCommonState} from '../types/state'
import {getLanguage, getMultilingualFullLocale} from '../utils/wix-code-api'

export const SET_BASE_ENVIRONMENT = 'SET_BASE_ENVIRONMENT'
export const SET_FORM_FACTOR = 'SET_FORM_FACTOR'

export const setBaseEnvironment = () => (
  dispatch: Dispatch,
  getState: GetCommonState,
  {wixCodeApi}: CommonStoreExtraArgs,
) => {
  const state = getState()
  const locale = getMultilingualFullLocale(wixCodeApi)

  dispatch({
    type: SET_BASE_ENVIRONMENT,
    payload: {
      locale: locale || wixCodeApi.site.regionalSettings || state.siteSettings.locale,
      language: getLanguage(wixCodeApi),
      timezone: wixCodeApi.site.timezone,
      mobile: wixCodeApi.window.formFactor === 'Mobile',
      editor: wixCodeApi.window.viewMode === 'Editor',
      preview: wixCodeApi.window.viewMode === 'Preview',
      site: wixCodeApi.window.viewMode === 'Site',
    },
  })
}

export const setFormFactor = (formFactor: string) => ({
  type: SET_FORM_FACTOR,
  payload: formFactor === 'Mobile',
})
